import Vue from 'vue'
import http from './axiosjs/axiosjs.js';
import './plugins/axios'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './main.css'
import './plugins/vant.js'

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import wxpay from 'weixin-jsapi'
Vue.prototype.wxpay = wxpay;
Vue.config.productionTip = false
Vue.prototype.$https = http


Vue.prototype.formatConversion = function (te){
	if(te == ''){
		return '';
	}else if(te.length == 10){
		var time = new Date(te * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
		var y = time.getFullYear();
		var m = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
		var d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
		var h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
		var mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
		var s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
		var timedate = y + '-' + m + '-' + d + ' ' + h + ':' + mm;
		return timedate;
	}else{
		var time2 = new Date(te);
		var y2 = time2.getFullYear();
		var m2 = time2.getMonth() < 9 ? '0' + (time2.getMonth() + 1) : time2.getMonth() + 1;
		var d2 = time2.getDate() < 10 ? '0' + time2.getDate() : time2.getDate();
		var h2 = time2.getHours() < 10 ? '0' + time2.getHours() : time2.getHours();
		var mm2 = time2.getMinutes() < 10 ? '0' + time2.getMinutes() : time2.getMinutes();
		var s2 = time2.getSeconds() < 10 ? '0' + time2.getSeconds() : time2.getSeconds();
		var timedate2 = y2 + '-' + m2 + '-' + d2 + ' ' + h2 + ':' + mm2;
		return timedate2;
	}
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
