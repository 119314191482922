import Vue from 'vue'
import { Button } from 'element-ui'
import { Steps } from 'element-ui'
import { Step } from 'element-ui'
import { Loading } from 'element-ui'
import { InfiniteScroll } from 'element-ui'

import { Table } from 'element-ui'
import { TableColumn } from 'element-ui'

import { Form } from 'element-ui'
import { FormItem } from 'element-ui'


import { Switch } from 'element-ui'
Vue.use(Loading)
Vue.use(Button)
Vue.use(Steps)
Vue.use(InfiniteScroll)
Vue.use(Step)
Vue.use(Table);
Vue.use(TableColumn);

Vue.use(Switch)

Vue.use(Form)
Vue.use(FormItem)