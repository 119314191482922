// import { reject, resolve } from "core-js/fn/promise"
var prefix = 'https://mall.yaolvxing.com/'
// var prefix = 'https://wx.yaolvxing.com/'
var header = 'content-type : application/x-www-form-urlencoded; charset=UTF-8'

// import axios from 'axios';
export default function http(url, data, method, is_show) {
  let promise;
  // 前态资源加载不显示加载框
  if (is_show != "false") {
    var load = this.$loading({
      lock: true,
      text: '加载中',
      background: 'rgba(0, 0, 0, 0.7)'
    });
  }

  let https = prefix + url
  return new Promise((resolve, reject) => {
    //GET
    if (method == 'get') {
      promise = this.axios.get(https + "?" + data).then(res => {
        resolve(res)
        is_show != 'false' ? load.close() : ''
      }).catch(err => { 
        is_show != 'false' ? load.close() : ''
        reject(err);
      })
    }
    //POST
    if (method == 'post') {
      promise = this.axios.post(https, data).then(res => {
        is_show != 'false' ? load.close() : ''
        resolve(res)
      }).catch(err => {
        is_show != 'false' ? load.close() : ''
        reject(err);
      })
    }
  })
}