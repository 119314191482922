<template>
  <div id="app">
    <!-- <index /> -->
   <keep-alive>   <!--数据缓存视图 -->
      <router-view v-if="$route.meta.keepAlive"></router-view>
  </keep-alive>
  <!--无缓存视图 -->
      <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
// import index from './views/index.vue'

export default {
  name: 'app',
}
</script>

<style>

</style>
