
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const classification = () => import('../views/classification.vue')
const index = () => import('../views/index.vue')
const cart = () => import('../views/cart.vue')
const personalcenter = () => import('../views/personalcenter.vue')
const details = () => import('../further/details.vue')
const submission = () => import('../further/submission.vue')
const collagenumber = () => import('../collage/collagenumber.vue')
const collagedot = () => import('../collage/collagedot.vue')
const user = () => import('../collage/user.vue')
const addresslist = () => import('../many/addresslist.vue')
const addressup = () => import('../many/addressup.vue')
const more = () => import('../many/more.vue')
const collmore = () => import('../collage/collmore.vue')
const productlist = () => import('../collage/productlist.vue')
const balance = () => import('../many/balance.vue')
const morenext = () => import('../many/morenext.vue')
const collagetobayto = () => import('../collage/collagetobayto.vue')
const chain = () => import('../chain/chain.vue')
const mode_index = () => import('../mode_xuanzuo/mode_index.vue')
const xz_index = () => import('../chain/xz_index.vue')
const inset = () => import('../chain/inset.vue')

const routes = [
  {
    path:'/',
    name : 'index',
    component : index,
    meta: {
      keepAlive: true,
    }
  },
  {
    path:"/classification",
    name : 'classification',
    component:classification,
    meta: {
      keepAlive: true,
      isBack:false,
    }
  },
  {
    name :'cart',
    path:"/cart",
    component:cart,
    meta: {
      keepAlive: true,
    }
  },
  {
    path:"/personalcenter",
    component:personalcenter,
    name : 'personalcenter',
  },  
  {
    path:"/further/details",
    name : 'details',
    component:details,
    meta: {
      keepAlive: true,
    }
  }, 
  {
    path:"/further/submission",
    name : 'submission',
    component:submission,
    meta: {
      keepAlive: true,
    }
  }, 
   {
    path:"/collage/collagenumber",
    name : 'collagenumber',
    component:collagenumber
  },
  {
    path:"/collage/collagedot",
    name : 'collagedot',
    component:collagedot,
    meta: {
      keepAlive: true,
    }
  }, 
  {
    path:"/many/addresslist",
    name : 'addresslist',
    component:addresslist
  },
  {
    path:"/many/addressup",
    name : 'addressup',
    component:addressup
  },
  {
    path:"/many/more",
    name : 'more',
    component:more
  },
  {
    path:"/collage/collmore",
    name : 'collmore',
    component:collmore
  }, 
  {
    path:"/many/balance",
    name : 'balance',
    component:balance
  }, 
  {
    path:"/collage/productlist",
    name : 'productlist',
    component:productlist,
    meta: {
      keepAlive: true,
      isBack:false,
    }
  }, 
  {
    path:"/collage/user",
    name : 'user',
    component:user
  }, 
  {
    path:"/morenext",
    name : 'morenext',
    component:morenext
  }, 
  {
    path:"/collagetobayto",
    name : 'collagetobayto',
    component:collagetobayto
  }, 
  {
    path:"/chain",
    name : 'chain',
    component:chain
  }, 
  {
    path:"/mode_index",
    name : 'mode_index',
    component:mode_index
  }, 
  {
    path:"/xz_index",
    name : 'xz_index',
    component:xz_index
  }, 
  {
    path:"/inset",
    name : 'inset',
    component:inset
  }, 
  {
    path:"/chaxun",
    name : 'chaxun',
    component:()=>import('../chain/chaxunh.vue')
  }, 
  {
    path:"/mu",
    name : 'mu',
    component:()=>import('../chain/mu.vue')
  }, 
  {
    path:"/muorder",
    name : 'muorder',
    component:()=>import('../chain/muorder.vue')
  }, 
  {
    path:"/hlogin",
    name : 'hlogin',
    component:()=>import('../chain/hlogin.vue')
  }, 
  {
    path:"/muxz",
    name : 'muxz',
    component:()=>import('../chain/muxz.vue')
  }, 
  // 
]
// 

const router = new VueRouter({
  // mode: 'history',
  // history
  base: process.env.BASE_URL,
  routes
})
export default router
