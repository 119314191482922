import Vue from 'vue'
import { Button } from 'vant'
import { Icon } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Lazyload } from 'vant';
import { Search } from 'vant';
import { Grid, GridItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { TreeSelect } from 'vant';
import { Image as VanImage } from 'vant';
import { Empty } from 'vant';
import { Card } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { SubmitBar } from 'vant';
import { Divider } from 'vant';
import { GoodsAction, GoodsActionIcon, GoodsActionButton } from 'vant';
import { Sku } from 'vant';
import { ContactCard } from 'vant';
import { CouponCell, CouponList } from 'vant';
import { Popup } from 'vant';
import { AddressList } from 'vant';
import { Dialog } from 'vant';
import { AddressEdit } from 'vant';
import { Area } from 'vant';
import { Tab, Tabs } from 'vant';
import { ActionSheet } from 'vant';
import { Field } from 'vant';
import { Cell, CellGroup } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Loading } from 'vant';
import { List } from 'vant';
import { Form } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Toast } from 'vant';
import { Step, Steps } from 'vant';
import { ImagePreview } from 'vant';


Vue.use(Dialog);
Vue.use(ImagePreview);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Form);
Vue.use(List);
Vue.use(Loading);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(ActionSheet);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Area);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(Popup);
Vue.use(CouponCell);
Vue.use(CouponList);
Vue.use(Sku);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Divider);
Vue.use(Card);
Vue.use(Empty);
Vue.use(VanImage);
Vue.use(TreeSelect);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Search);
Vue.use(Button)
Vue.use(Icon)
Vue.use(Swipe);
Vue.use(Toast);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(SubmitBar);
Vue.use(ContactCard);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);